import {RouterProvider, createBrowserRouter} from "react-router-dom";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Home from "./pages/home/Home"; 
import Project from "./pages/project/Project";
import Service from "./pages/service/Service";
import Layout from "./components/layout/Layout";
import ErrorPage from "./pages/notfound/ErrorPage";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Layout />,
		errorElement:<ErrorPage/>,
		children: [
			{
				path: "/",
				element: <Home />,
			},
			{
				path: "/about",
				element: <About />,
			},

			{
				path: "/service",
				element: <Service />,
			},

			{
				path: "/project",
				element: <Project />,
			},

			{
				path: "/contact",
				element: <Contact />,
			}, 
			 
		],
	},
]);

const App = () => {
	return <RouterProvider router={router} />;
};

export default App;
