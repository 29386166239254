import {Link} from "react-router-dom";
import styles from "./home.module.css";


export default function Home() {
	return (
		<div className={styles.container}>
			<div className={styles.leftContainer}>
				<h1 className={styles.title}>
					Bölgesinde otomatik kapı ve panjur sistemlerinde lider!{" "}
				</h1>
				<p className={styles.desc}>
					ŞenerKAPI ihtiyacınız olduğunda her daim yanınızda olacaktır!
				</p>
				<Link to="/contact" className={styles.button}>
					Teklif İsteyin
				</Link>
			</div>
			<div className={styles.rightContainer}>
				<img className={styles.img} src="/image/fotoselkapi2.png" alt="fotoselkapi" />
			</div>
		</div>
	);
}
