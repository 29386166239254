 

import styles from "./footer.module.css";
import {Link} from "react-router-dom";


const Footer = () => {
	return (
		<div className={styles.container}>
			<div className={styles.left}>
				<Link> &copy; {new Date().getFullYear()} senerkapi.com</Link>
			</div>
			<div className={styles.right}>
				<Link
					to="https://www.facebook.com/senerkepenk/?locale=tr_TR"
					target=""
				>
					<img
						className={styles.icon}
						src="image/facebook.svg"
						width={32}
						height={32}
						alt="facebook"
					/>
				</Link>
			</div>
		</div>
	);
};

export default Footer;
