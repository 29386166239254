import styles from "./project.module.css";

const Project = () => {
	const data = [
		{
			id: 1,
			name: "Güvenlik Bariyeri",
			place: "Kahta Emniyet Müdürlüğü",
			url: "image/kahta-emniyet.jpg",
		},
		{
			id: 2,
			name: "Fotoselli Kapı",
			place: "Menzil Köyü",
			url: "image/fotoselkapi_menzil.png",
		},
		{
			id: 3,
			name: "Araç Kapanı",
			place: "Sincik Devlet Hastanesi",
			url: "image/sincik_bariyer3.jpg",
		},
		{
			id: 4,
			name: "Fotoselli Kapı",
			place: "Besni Devlet Hastanesi",
			url: "image/besni_devlet_hastanesi.png",
		},
		{
			id: 5,
			name: "Fotoselli Kapı",
			place: "Mardin Kızıltepe Devlet Hastanesi",
			url: "image/mardin_kiziltepe_fotosel Kapi.jpg",
		},
		{
			id: 6,
			name: "Fotoselli Kapı",
			place: "Besni ilçe Emniyet Müdürlüğü Ek Hizmet Binası",
			url: "image/besni_emniyet_Ekbina.jpg",
		},
		{
			id: 7,
			name: "Endüstriyel Kapı",
			place: "Adıyaman Türkiye Petrolleri Yerleşkesi",
			url: "image/TOPA_endustriyel_kapi.png",
		},
		{
			id: 8,
			name: "Otopark Araç Kapını",
			place: "Yeni Kadın Doğum ve Çocuk Hastanesi",
			url: "image/yeni_kadin_dogum.jpg",
		},

		{
			id: 9,
			name: "Otomatik Uzaktantan Kumandalı Kepenk",
			place: "Adıyaman Merkez",
			url: "image/otomatik_uzaktan_kumandali_kepenk.jpg",
		},
	];
	return (
		<div className={styles.container}>
			<h1 className={styles.title}>Projelerimiz</h1>
			<div className={styles.itemContainer}>
				{data.map((item) => (
					<div className={styles.imgContainer} key={item.id}>
						<img
							className={styles.img}
							src={item.url}
							width={350}
							height={340}
							alt={item.name}
						/>
						<div className={styles.content}>
							<h1 className={styles.imageTitle}>{item.name}</h1>
							<span className={styles.imagePlace}>{item.place}</span>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Project;
