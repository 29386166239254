import {useState} from "react";
import {Link} from "react-router-dom";
import styles from "./navbar.module.css";

const Navbar = () => {
	const links = [
		{
			id: 1,
			name: "Ana Sayfa",
			url: "/",
		},
		{
			id: 2,
			name: "Hakkımızda",
			url: "/about",
		},

		{
			id: 3,
			name: "Hizmetler",
			url: "/service",
		},

		{
			id: 4,
			name: "Projeler",
			url: "/project",
		},
		{
			id: 5,
			name: "İletişim",
			url: "/contact",
		},
	];

	const [isOpen, setIsOpen] = useState(false);

	function handleClick() {
		setIsOpen((isOpen) => !isOpen);
	}

	return (
		<div className={styles.container}>
			<div className={styles.navbarContainer}>
				<div className={styles.logoContainer}>
					<Link to="/">
						<img className={styles.logo} src="logo.png" alt="logo" />
					</Link>
					<span className={styles.logoTitle}>
						Otomatik Kapı Sistemleri
					</span>
				</div>
				<div className={styles.togleContainer} onClick={handleClick}>
					<div className={!isOpen ? styles.line : styles.lineOpen}></div>
					<div className={!isOpen ? styles.line : styles.lineOpen}></div>
					<div className={!isOpen ? styles.line : styles.lineOpen}></div>
				</div>
			</div>

			<div
				className={
					!isOpen ? styles.linksContainer : styles.linksContainerClosed
				}
			>
				{links.map((link) => (
					<Link
						className={styles.link}
						key={link.id}
						to={link.url}
						onClick={handleClick}
					>
						{link.name}
					</Link>
				))}
			</div>
		</div>
	);
};

export default Navbar;
