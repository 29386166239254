import styles from "./service.module.css";

const Service = () => {
	const data = [
		{id: 1, name: "Endüstriyel Kapı", url: "/image/TOPA_endustriyel_kapi.png"},
		{id: 2, name: "Fotoselli Kapı", url: "/image/fotoselkapi2.png"},
		{id: 3, name: "Kollu Bariyer", url: "/image/kollu_bariyer.png"},
		{id: 4, name: "Garaj Kapıları", url: "/image/otomatikkapi.png"},
		{id: 5, name: "Bahçe Kapı Motorları", url: "/image/kapi_moturu.png"},
		{id: 6, name: "Dönerli Kapı", url: "/image/donerlikapi.jpg"},
		{id: 7, name: "Mantar Bariyer", url: "/image/mantar_bariyer.png"},
		{id: 8, name: "Road Blocker", url: "/image/bariyer_sistemi.png"},
		{id: 9, name: "Hızlı PVC Kapılar ve Kepenkler", url: "/image/panjur3.jpg"},
	];
	return (
		<div className={styles.container}>
			<h1 className={styles.title}>Hizmetlerimiz</h1>
			<div className={styles.itemContainer}>
				{data.map((item) => (
					<div className={styles.imgContainer} key={item.id}>
						<img
							className={styles.img}
							src={item.url}
							width={350}
							height={350}
							alt={item.name}			
						/>
						<h1 className={styles.imageTitle}>{item.name}</h1>
					</div>
				))}
			</div>
		</div>
	);
};

export default Service;
