import {Outlet} from "react-router-dom";
import "../../index.css";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";

const Layout = () => {
	return (
		<div className="container">
			<Navbar />
			<Outlet />
			<Footer />
		</div>
	);
};

export default Layout;
