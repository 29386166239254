 
import styles from "./about.module.css";

const About = () => {
	return (
		<div className={styles.container}>
			<div className={styles.rightContainer}>
				<img className={styles.image} src="/image/aboutimg.svg" alt="" width={350} height={500} />
			</div>
			<div className={styles.leftContainer}>
				<h1 className={styles.title}>
					Hakkımızda
				</h1>
				<p className={styles.desc}>
					<strong> Şener Kapı ve Kepenk Otomasyonları </strong>20 yıldır müşterilerinin
					ihtiyaçları doğrultusunda hizmet vermektedir.
					<br />
					<br />
					Kaliteli ürünleri, uygun fiyatlarla ve kaliteli işciliklikle
					birleştirerek müşteri memnuniyetini kendine ilke edinmiş bir kuruluş
					olmanının grurunu yaşamaktayız.
					<br />
					<br />
					Esnek çalışma saatleri ile her an müşterilerimizin ihtiyaç duydukları
					güvenlik otomasyonları, kapı ve kepenk sistemlerinin bakım ve
					onarımları için bir telefon mesaafesindeyiz.
				</p>
			</div>
		</div>
	);
};

export default About;
