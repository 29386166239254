import styles from "./contact.module.css";

const Contact = () => {
	return (
		<div className={styles.container}>
			<h1 className={styles.title}>Bize Ulaşın</h1>

			<div className={styles.contactInfoContainer}>
				<div className={styles.rightContainer}>
					<img
						className={styles.imgContact}
						src="/image/contact_img.svg"				
						alt="contact"												
					/>
				</div>
				<div className={styles.leftContainer}>
					<h1 className={styles.subtitle}>Telefon</h1>

					<div className={styles.itemContainer}>
						<img
							src="/image/icons8-phone-50.png"
							width={72}
							height={72}
							className={styles.icon}
							alt="phone"
						/>
						<span className={styles.text}><a href="tel:+904162169222" className="nav-linker">0416 216 9222</a></span>
					</div>

					<div className={styles.itemContainer}>
						<img
							src="/image/icons8-phone-50(1).png"
							width={72}
							height={72}
							className={styles.icon}
							alt="phone"
						/>
						<span className={styles.text}><a href="tel:+905422499222"> 0542 249 9222</a></span>
					</div>

					<h1 className={styles.subtitle}>Elektronik Posta</h1>
					<div className={styles.itemContainer}>
						<img
							src="/image/icons8-email-50.png"
							width={72}
							height={72}
							className={styles.icon}
							alt="phone"
						/>
						<span className={styles.text}><a href="mailto:info@senerkapi.com">info@senerkapi.com</a></span>
					</div>

					<h1 className={styles.subtitle}>Adres</h1>
					<div className={styles.itemContainer}>
						<img
							src="/image/icons8-location-50.png"
							width={72}
							height={72}
							className={styles.icon}
							alt="phone"
						/>
						<span className={styles.text}>
							Bahçelievler Mah. 904. Sk. No:18/A Adıyaman
						</span>
					</div>
				</div>
			</div>

			<div className={styles.mapContainer}>
				<iframe
					className={styles.map}
					src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d1930.4657829671291!2d38.28070687524565!3d37.761488711241945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x1533137b73f52399%3A0x1318494f9e77a8!2sBah%C3%A7elievler%20Mah.%20904.%20Sk.%20No%3A18!3m2!1d37.7619186!2d38.282660199999995!5e0!3m2!1sen!2str!4v1693749202759!5m2!1sen!2str"
					allowfullscreen=""
					loading="lazy"
					title="google-map"
					referrerpolicy="no-referrer-when-downgrade"
				></iframe>
			</div>
		</div>
	);
};

export default Contact;
